.l-main-container{
    display: flex;
    gap: 20px;
    width: 100%;
    @include mq-down(lg){
        display: block;
    }

}

#fix_sidebar{
    z-index: 10;
    position: sticky;
    top: 85px;
}

body.l-sidebar-left {
    .l-main-container,
    #content_area {
        flex-direction: row-reverse;
    }
}