
#content_area{
    article.hentry{
        margin: 0 !important;
    }
}

#main, #single-main, #page-main{
    float: left;
}

#sidebar {
    float: right;
}

.l-main-container{
    #main,
    #single-main,
    #page-main,
    #sidebar{
        float: none;
    }
}


/****************************************

          navigation

*****************************************/

.navigation{
    overflow: hidden;
      margin:10px 0;
      background: #fff;
  
  }
  
  .navigation > div{
    width: 50%;
  }
  
  .navigation .right{
    text-align: right;
    border-top: 1px solid #ccc;
  }
  
  .navigation .left a:before{
    font-family: fontAwesome;
    content:"\f053";
    margin-right: 10px;
      vertical-align: middle;
  }
  
  .navigation .right a:after{
    font-family: fontAwesome;
    content:"\f054";
    margin-left: 10px;
      vertical-align: middle;
  }
  
  .navigation a{
    display: block;
    padding: 1em;
    border: none;
  }
  
  .navigation a:hover{
    background: #eef;
    color: #000;
  }
  .navigation
   .alignright a:hover{
    border-left: 1px solid #ddd;
  }
  
  @media screen and (max-width:768px){
    .navigation a{
      padding: 1em ;
      font-size: .6em;
    }
  }
  