@import "../module/settings";

.button,
.content .button{
    border: none;
    background: no-repeat;
    text-align: center;
    margin: 10px 5px;
    a{
        padding: 0.8em 1.8em;
        font-size: .9em;
        display: inline-block;
        position: relative;
        background-color: #70b8f1;
        border-style: solid;
        border-width: 0px;
        color: #fff;
        font-weight: bold;
        text-decoration: none !important;
        letter-spacing: .1em;
        border-radius: 3px;
        i{
            vertical-align: baseline;
            line-height: 1;
            &.before {
                margin-right: 1em;
            }
            &.after {
                margin-left: 1em;
            }
        }
        &.big {
            padding: 0.8em 2.5em;
            font-size: 1.1em;
        }
        &.small {
            padding: 0.5em 1.3em;
            font-size: .7em;
        }
        &:hover{
            filter: brightness(0.95);
        }
    }

    &.big a{
        width: 100%;
    }
    
    &.inline{
        display: inline-block;
    }
    &.solid a {
        position: relative;
        border-bottom: 5px solid #ccc;
        top: 0px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    }
    &.shadow a {
        box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
        &:hover{
            box-shadow: none;
        }
    }
    &.radius a {
        border-radius: 50em;
    }
    &.bborder a {
        border: 2px solid #6f8e9c;
    }
    &.oborder a {
        border: 2px solid #70b8f1;
        background: #fff;
        color: #70b8f1;
    }
    &.frame a {
        box-shadow: 0px 0px 0px 2px #55bed5;
        border: 2px solid #ffffff;
    }


    @each $key, $color in $auxiliary-color {
        &.#{$key}{
            a{
                color: map-get($color, "text");
                background-color: map-get($color, "bg");
            }
            &.bborder a{
                border-color: darken(map-get($color, "bg"),10%);
                background-color: map-get($color, 'bg');
                color: #fff;
            }
            &.oborder a{
                border-color: map-get($color, "bg");
                background: map-get($color, "text");
                color: map-get($color, "bg");
            }
            &.frame a{
                box-shadow: 0px 0px 0px 2px map-get($color, "bg");
            }
            &.solid a{
                border-bottom: 5px solid darken(map-get($color, "bg"), 20%);
            }
        }

    }
    &.white.bborder a{
        border-color: #000;
        color:#000;
    }

}