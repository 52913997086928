.content {
    line-height: 1.8;
    position: relative;
    word-break: normal;
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
    .sub-text{
        opacity: .6;
        font-size: .9em;
    }
}

i span {
    display: none;
}

:where(.content) {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1.5em;
    }

    .padding0 img {
        margin: 0;
    }

    p.has-background {
        padding: 20px 30px;
    }

    .post-upon {
        padding: 20px;
        padding-top: 0;
        background: #fff;
    }

    ul,
    ol,
    table,
    dl {
        margin-bottom: 1.2em;
    }

    ul {
        list-style-type: disc;
        padding-left: 1.5em;
        line-height: 2;
        li {
            line-height: 1.5;
            margin-bottom: 0.6em;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 1.5em;
        line-height: 2;
    }

    a{
        color: #6495ed;
        word-break: break-all;
    }
}

/* page link -----------------------------------------*/

.content-pager-link {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    font-size: 0.9em;

    .post-page-numbers {
        background: #fff;
        color: #333;
        box-shadow: $box-shadow;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        &.current {
            background: #333;
            color: #fff;
        }
        &:not(.current):hover {
            background-color: #eee;
        }
    }

    &__buttons {
        .post-page-numbers {
            width: auto;
            padding: 12px 18px;
            display: inline-block;
            .prev:before {
                font-family: $font-awesome;
                content: "\f053";
            }
            .next:after {
                font-family: $font-awesome;
                content: "\f054";
                margin-left: 8px;
            }
        }
    }
    &__pagination {
        display: flex;
        grid-gap: 5px;
        .post-page-numbers {
            line-height: 36px;
            width: 36px;
        }
    }
}