@import "../module/settings";

.barchart {
    display: block;
    background: #006d97;
    text-align: left;
    padding-left: 20px;
    color: #f0ffff;
    height: 2.5em;
    line-height: 2.5em;
    margin: 5px 0;
    border-radius: 0 10px 10px 0;
}

.diver-barchart {
    position: relative;
    margin-bottom: 1em;
    &__title {
        font-size: 1.2em;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 6px;
    }
    &__bar {
        background: #eee;
        position: relative;
        height: 1em;
        border-radius: 2em;
        &--value {
            position: absolute;
            left: 0;
            height: 100%;
            border-radius: 2em;
        }
    }
    &__label {
        position: absolute;
        display: inline-block;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
        background: #eee;
        border-color: #eee;
        box-shadow: 0 0 5px 0px #ccc;
        color: #f00;
        font-weight: bold;
        font-size: 0.8em;
        padding: 0.2em 0.6em;
        border-radius: 5px;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: calc(50% - 6px);
            border-width: 8px 6px 0;
            border-style: solid;
            border-color: transparent;
            border-top-color: inherit;
        }
        &.is-position-bottom {
            top: 1em;
            bottom: auto;
            position: relative;
            &:before {
                bottom: 100%;
                top: auto;
                border-bottom-color: inherit;
                border-width: 0 6px 8px;
            }
        }
    }
}