@import "../module/settings";

.sc_designlist {
    margin-bottom: 1.5em;
    img {
        margin: 1em 0;
    }
    & > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 2em;
        & > li {
            position: relative;
            padding-left: 1.5em;
            &:before {
                font-family: "fontAwesome";
                position: absolute;
                left: 0;
                top: -0.1em;
                font-size: 1.2em;
                color: #668ad8;
            }
        }
    }

    &.fa_check > ul > li:before {
        content: "\f00c";
    }
    &.fa_angle > ul > li {
        padding-left: 1em;
        &:before {
            content: "\f105";
        }
    }
    &.fa_angle_d > ul > li:before {
        content: "\f101";
    }
    &.fa_angle_o > ul > li:before {
        content: "\f138";
    }
    &.fa_caret > ul > li {
        padding-left: 1em;
        &:before {
            content: "\f0da";
        }
    }
    &.fa_arrow > ul > li:before {
        content: "\f061";
    }
    &.lborder > ul {
        padding: 0;
        & > li {
            border-left: 5px solid #668ad8;
            padding-left: 0.5em;
        }
    }

    & > ol {
        list-style: none;
        counter-reset: number;
        line-height: 2em;
        padding: 0;
        margin: 0;
        & > li {
            position: relative;
            margin-bottom: 0.5em;
            padding-left: 2em;
            &:after {
                position: absolute;
                counter-increment: number;
                content: counter(number);
                display: inline-block;
                font-weight: bold;
                font-size: .9em;
                left: 0;
                top: 0.2em;
                width: 1.8em;
                line-height: 1.8em;
                text-align: center;
                white-space: nowrap;
                box-sizing: content-box;
            }
        }
    }
    &.solid > ol > li:after {
        background: #668ad8;
        color: #fff;
    }
    &.reg > ol > li:after {
        border: 2px solid #668ad8;
        color: #668ad8;
        background-color: transparent;
        width: calc(1.8em - 4px);
        line-height: calc(1.8em - 4px);
    }
    &.dia > ol > li {
        &:after {
            color: #fff;
            width: 1.6em;
            line-height: 1.6em;
        }
        &:before {
            position: absolute;
            top: 0.2em;
            left: 0;
            width: 1.4em;
            height: 1.4em;
            content: "";
            transform: rotate(45deg);
            background: #668ad8;
        }
    }
    &.radius > ol > li:after {
        border-radius: 50%;
    }
    &.timeline > ol > li {
        &::after {
            border: 2px solid #668ad8;
            color: #668ad8;
            border-radius: 50%;
            background: #fff;
            width: calc(1.8em - 4px);
            line-height: calc(1.8em - 4px);
        }
        &::before {
            position: absolute;
            z-index: 0;
            top: 1em;
            left: calc(.9em - 2px);
            height: calc(100% + 1em);
            content: "";
            border-left: 2px dotted #668ad8;
        }
        &:last-child::before {
            display: none;
        }
    }
    &.parag > ol {
        margin-left: 2em;
        & > li {
            padding-left: 0.5em;
            border-left: 3px solid #98b9da;
            &::after {
                font-size: 1.5em;
                right: calc(100% + 0.5em);
                left: auto;
                width: auto;
                color: #668ad8;
                line-height: 1em;
                text-align: right;
            }
        }
    }

    @each $key, $color in $auxiliary-color {
        &.#{$key} {
            & > ul > li::before {
                color: map-get($color, "bg");
            }
            &.lborder > ul > li {
                border-color: map-get($color, "bg");
            }
            &.dia > ol > li::before,
            &.solid > ol > li:after {
                background: map-get($color, "bg");
                color: map-get($color, "text");
            }
            &.reg > ol > li::after {
                color: map-get($color, "bg");
                border-color: map-get($color, "bg");
            }

            &.parag > ol > li {
                border-color: map-get($color, "bg");
                &:after {
                    color: map-get($color, "bg");
                }
            }
            &.timeline > ol > li {
                &:after {
                    border-color: map-get($color, "bg");
                    color: map-get($color, "bg");
                }
                &:before {
                    border-color: map-get($color, "bg");
                }
            }
        }
    }
}
