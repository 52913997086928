/* HTML5
-------------------------------------------------- */
div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, 
blockquote, pre, abbr, address, cite, code, del, dfn, 
em, img, ins, kbd, q, samp, small, strong, sub, sup, var, 
b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, 
table, caption, tbody, tfoot, thead, tr, th, td, article, 
aside, canvas, details, figcaption, figure, footer, header, 
hgroup, menu, nav, section, summary, time, mark, audio, video, input, textarea{
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    font-style: normal;
    // background-color: inherit;
}

audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

mark{
  background-color: yellow;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

.screen-reader-text,
.hide,
[hidden] {
  display: none;
}

@include mq-down(sm){
  .sp_hide{
    display: none;
  }
}




/* all
-------------------------------------------------- */

body{
  vertical-align: baseline;
  font-smoothing: antialiased;
  text-rendering: auto;
  margin: 0;
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  font-family: 游ゴシック体, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic",FontAwesome, sans-serif;

}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
}

:where(a){
  transition: all ease-in-out .3s;
  text-decoration: none;
  &:focus,
  &:active{
    outline: 0;
  }
  &:hover{
    color: #6495ED;
    outline: 0;
    opacity: .8;
  }
  color: inherit;
}

*, *:before, *:after {
  box-sizing: border-box;
}


img,
iframe{
    max-width: 100%;
    vertical-align: middle;
}

/* clear
-------------------------------------------------- */


.content .row{
  vertical-align: middle;
}

.row.padding0 {
    margin: auto;
}

.clearfix,
.cf{
  zoom:1;
//   clear: both;
  display: block;
  &:after{
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    font-size: 0;
  }
}


/* text
-------------------------------------------------- */

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

em{
  font-style: italic;
}


dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}



code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: .9em;
  overflow-x: scroll;
}

code{
  background: #eee;
  border: 1px solid #ccc;
  padding: 2px 5px;
  border-radius: 2px;
  margin: 1px 2px;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


/* list
-------------------------------------------------- */

ul,ol {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

ul ul{
  margin-left: 1em;
}


/* embed
-------------------------------------------------- */

img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
  font-size: 10px;
  color: #888;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}


/* form
-------------------------------------------------- */

form {
    margin: 0;
}

fieldset {
  border: 1px solid #ccc;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"],
.btn {
  overflow: visible;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  height: 13px;
  width: 13px;
}

input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}



/* form
---------------------------------------- */

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea {
    margin: 0;
    padding: 8px 4px;
    max-width: 100%;
    width: 80%;
    outline: none;
    border: 1px solid #ddd;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #777;
    vertical-align: bottom;
    font-size: 13px;
    background-color: #F7F7F7;
    &:focus{
      background: #fff;
      box-shadow:  0 0 3px #eee;
      color: #444;
    }
}

input[disabled],
textarea[disabled] {
  background-color:#eee;
  color:#999;
  box-shadow:none;
}


input::placeholder {
  color: #ccc;
}

textarea {
    overflow: auto;
}


button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    display: inline-block;
    padding: .5em 1.5em;
    border-radius: 3px;
    text-decoration: none;
    line-height: normal;
    cursor: pointer;
    &::-webkit-search-decoration {
      display: none;
    }
    &:focus{
      outline-offset: -2px;
    }
}


/* table
-------------------------------------------------- */

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
}
th,td{
  border: 1px solid #ccc;
  padding: 8px 13px;
}


/* icon
-------------------------------------------------- */

i span {
    display: none !important;
}