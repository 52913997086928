
// shadow --------------------------------

$box-shadow:0 5px 5px 0 rgba(18,63,82,0.035), 0 0 0 1px rgba(176,181,193,0.2);

// auxiliary color --------------------------------

$auxiliary-color: (
  'white':(
    'text':#000,
    'bg':#f9f9f9
  ),
  'black':(
    'text':#fff,
    'bg':#000
  ),
  'blue':(
    'text':#fff,
    'bg':#70b8f1
  ),
  'red':(
    'text':#fff,
    'bg':#ff8178
  ),
  'yellow':(
    'text':#000,
    'bg':#ffe822
  ),
  'orange':(
    'text':#fff,
    'bg':#ffa30d
  ),
  'green':(
    'text':#fff,
    'bg':#7acc40
  ),
  'gray':(
    'text':#000,
    'bg':#ccc
  )
);

// responsive ----------

// min-width
$breakpoint-up: (
  'sm': 'screen and (min-width: 600px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 960px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

// max-width
$breakpoint-down: (
  'sm': 'screen and (max-width: 599px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 959px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;

@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin responsive-down($class, $bp: $breakpoint-down) {
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

@mixin responsive-up($class, $bp: $breakpoint-up) {
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

// fontAwesome --------------------------------

$font-awesome:'fontAwesome';