@import "../module/settings";

.badge {
    background: #333;
    color: #fff;
    padding: .5em .8em .4em;
    line-height: 1;
    font-size: 0.8em;
    border-radius: 3px;
    margin: .2em .4em;
    top:-0.1em;
    display: inline-block;
    position: relative;
    font-weight: bold;
    &.btag {
        margin-left: 10px;
        border-radius: 0 3px 3px 0;
        &:before,
        & .beforespan {
            position: absolute;
            top: 0;
            left: -1em;
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #333 transparent transparent;
            border-style: solid;
            border-width: 1em 1em 1em 0;
        }
        .beforespan{
            border-color: transparent;
        }
        &:after {
            position: absolute;
            top: 50%;
            left: -1px;
            z-index: 2;
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            margin-top: -3px;
            background-color: #fff;
            border-radius: 100%;
        }
    }

    &.radius {
        border-radius: 50px;
    }

    &.cornertag {
        border-radius: 50px 3px 3px 50px;
        padding-left: 20px;
        &::before {
            position: absolute;
            top: 50%;
            left: 10px;
            z-index: 2;
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            margin-top: -3px;
            background-color: #fff;
            border-radius: 100%;
        }
    }

    &.bborder {
        border: 2px solid #333;
        background: #fff;
        color: #333;
        font-weight: bold;
    }
    
    &.bborder-radius {
        border: 2px solid #333;
        color:#333;
        background-color: transparent;
        border-radius: 50px;
    }

    &.v {
        margin-right: .8em;
        &::before,
        & .beforespan {
            content: "";
            position: absolute;
            right: -.8em;
            top: 50%;
            transform: translate(0, -50%);
            width: 0;
            height: 0;
            border-color: transparent #333 transparent;
            border-style: solid;
            border-width: .5em 0 .5em .8em
        }
        .beforespan{
            border-color: transparent;
        }
    }

    i {
        vertical-align: baseline;
        font-weight: bold;
        .before {
            margin-right: 0.5em;
        }
        .after {
            margin-left: 0.5em;
        }
    }

    @each $key, $color in $auxiliary-color {
        &.#{$key} {
            background-color: map-get($color, "bg");
            color: map-get($color, "text");
            &.bborder {
                background-color: transparent;
                border-color: map-get($color, "bg");
                color: map-get($color, "bg");
            }
            &.bborder-radius {
                background-color: transparent;
                border-color: map-get($color, "bg");
                color: map-get($color, "bg");
            }
            &.v:before{
                border-color: transparent map-get($color, "bg") transparent;
            }
            &.btag:before{
                border-color: transparent  map-get($color, "bg") transparent transparent;
            }

        }
    }
}
