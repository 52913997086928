@keyframes deer_skeleton_animation{
    0%{
      transform: translateX(-100%);
    }
    100%{
      transform: translateX(100%);
    }
  }
  
  .deer-skeleton{
    width: 100%;
    height: 20px;
    background: #d9d9d9;
    position: relative;
    overflow: hidden;
    &::before{
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background:  linear-gradient(90deg, transparent, rgba(#fff, 0.5), transparent);
      position: absolute;
      top: 0;
      left: 0;
      animation: deer_skeleton_animation 1.2s linear infinite;
    }
  
  }
  

/* slider -----------------------------------------*/
.slider-container{
    .slider-inner{
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items:center;
        overflow: hidden;
        height: 120px;
        background-color: #fff;
        position: relative;

        & > li {
            display: none;
        }

        &::before{
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            background:  linear-gradient(135deg, transparent, rgba(#ccc, 0.5), transparent);
            position: absolute;
            top: 0;
            left: 0;
            animation: deer_skeleton_animation 1.2s linear infinite;
        }

        .slick-list{
            width: 100%;
            margin: 0 -2px;
        }

        .slick-slide{
            background-color: #fff;
            opacity: 0;
            min-height: 100px;
            // min-width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        .slick-track{
            display: flex;
            gap: 5px;
        }

        &.slick-initialized{
            height: inherit;
            background-color: transparent;
            .slick-slide{
                opacity: 1;
            }
            &::before{
                content: none;
            }
        }

        .slick-arrow{
            position: absolute;
            cursor: pointer;
            top: 50%;
            margin-top: -16px;
            outline: 0;
            width: 32px;
            line-height: 32px;
            z-index: 1;
            transition: none;
            border-radius: 50%;
            background-color: #000;
            color: #fff;
            opacity: .7;
            text-align: center;
            font-size: 21px;
            &:before{
                content: "\f105";
                font-family:"FontAwesome";
                font-weight: bold;
            }
            &.slick-next{
                right: 10px;
                left: auto;
            }
            &.slick-prev{
                left: 10px;
                right: auto;
                &:before{
                    content: "\f104";
                }
            }
        }

        .slider-dots{
            position: absolute;
            left: 50%;
            bottom: 5px;
            margin: 0;
            transform: translate(-50%,0px);
            white-space: nowrap;
            li{
                display:inline-block;
                cursor:pointer;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: 0 5px;
                background: #fff;
                opacity: .3;
                &:hover{
                    background:#ccc;
                }
                &.slick-active{
                    background:#ccc;
                    opacity: 1;
                }
            }
        }
    }
}  

/* pickup slider -----------------------------------------*/
.pickup-slider{
    width: 100%;
    max-width: 100%;
    .slick-slide{
        padding: 5px;
        & > a {
            position:relative;
            padding-top: 56.25%;
            height: 0;
            width: 100%;
            display: block;
        }

        img{
            object-fit: cover;
            font-family: 'object-fit:cover;';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .pickup-title{
            font-weight: bold;
            margin-bottom: .5em;
        }

        .pickup-dt{
            padding-left: 5px; 
            font-size: .8em;
        }

        .pickup-cat{
            position: absolute;
            top:0;
            right: 0;
            padding:3px 8px;
            background: #333;
            color:#fff;
            font-size: .6em;
            border-radius: 0 0 0 5px;
            margin: 0;
            font-weight: bold;
        }
        
        .meta{
            font-size: .8em;
            padding: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 65px;
            background: rgba(0,0,0,0.6);
            color: #fff;
            transition: .4s;
        }

        @include mq-up(md) {
        //     padding: 0 $md-gt;
        //    &:hover{
        //         filter: drop-shadow(0px 0px 3px #999);
        //         box-shadow: 0 0 5px rgba(0,0,0,0.2);
        //     }
          
           &:hover .meta{
                height: 100%;
            }
        }
        
    }
}

#header{
    .pickup-slider{
        .slick-track{
            gap: 0px;
            .slick-slide{
                padding: 0;
                .meta{
                    font-size: 1em;
                    background:linear-gradient(to bottom,rgba(30,87,153,0) 0%,rgba(0,0,0,.3) 40%,rgba(0,0,0,.6) 100%);
                    padding: 60px 5% 3%;
                    height: auto;
                }

                .pickup-cat {
                    padding: 6px 12px;
                    font-size: .8em;
                }
            }
        }
        .slider-inner:not(.slick-initialized){
            height:300px;
        }
    }
    

}

#main-wrap{
    #pickup_posts_container{
        margin-bottom: 10px;
    }
}