@import "../module/settings";

.diver-hr {
    border: 0;
    padding: 0;
    height: 1px;
    width: 100%;
    margin: 2em auto;
    background-color: #fff;
    text-align: center;
    overflow: visible;
    position: relative;
    &::before {
        background-color: inherit;
        color: inherit;
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 0.5em;
        line-height: 2em;
    }

    &.is-align-left{
        margin-left: 0;
    }
    &.is-align-right{
        margin-right: 0;
    }

    &.style-dot {
        background-repeat: repeat-x;
    }

    &.hr-content-text {
        &:before {
            content: attr(data-content);
            padding: 0 1em;
            font-size: .9em;
        }
    }
    &.hr-content-slice {
        text-align: left;
        &:before {
            content: "\002702";
            font-size: 1.2em;
            left: 20%;
        }
    }
    &.hr-content-heart:before {
        content: "\002665";
        font-size: 1.2em;
    }
}

.border{
    width:100%;
    height:2px;
    border-top: 2px solid #ccc;
    margin: 10px 0;
  }
  