@import "../module/settings";

.voice {
    margin-bottom: 1.2em;
    display: flex;
    align-items: flex-start;
    grid-gap: 18px;
    .icon {
        text-align: center;
        flex: none;
        img,
        amp-img {
            border-radius: 50%;
            border: 2px solid #eee;
            width: 70px;
            height: 70px;
            object-fit: cover;
            margin: 0;
            overflow: hidden;
            &.inverted {
                transform: scale(-1, 1);
            }

            & + p {
                display: none;
            }
        }
        .name {
            line-height: 1;
            font-size: 0.8em;
        }
        p {
            margin: 0;
            padding: 0;
        }
    }

    .sc_balloon {
        padding: 0.5em 1em;
        margin: 0;
        font-size: 0.9em;
        margin-top: 10px;
        border-radius: 10px;
    }

    .voice .sc_balloon.right:before, .voice .sc_balloon.left:before, .voice .sc_balloon.right:after, .voice .sc_balloon.left:after {
        top: 10px;
    }
    &.right {
        flex-direction: row-reverse;
        .sc_balloon {
            &:before{
                top:10px;
            }
            &:after{
                top:calc(10px + 3px);
            }
            @include mq-up(sm){
                margin-left: 70px;
            }
        }

        .custom_voice {
            left: 100%;
            top: calc(10px + 3px);
            border-right-color: #fff;
            border-style: solid;
        }

        .think_balloon {
            &::before {
                right: -25px;
            }
            &::after {
                right: -15px;
            }
        }
    }

    &.left {
        .sc_balloon {
            &:before{
                top:10px;
            }
            &:after{
                top:calc(10px + 3px);
            }
            @include mq-up(sm){
                margin-right: 70px;
            }
        }

        .custom_voice {
            right: 100%;
            top: calc(10px + 3px);
            border-right-color: #fff;
            border-style: solid;
        }

        .think_balloon {
            &::before {
                left: -26px;
            }
            &::after {
                left: -16px;
            }
        }
    }
}
// .content .voice p {padding: 0;}

// .voice.left.n_bottom .icon{float: left;}
// .voice.left.n_bottom .icon img{float: none;}
// .voice.left.n_bottom .icon .name{
//   margin-right: 20px;
//   text-align: center;
//   line-height: 1;
//   max-width: 70px;
//   margin-top: -10px;
// }
// .voice.right.n_bottom .icon{float: right;}
// .voice.right.n_bottom .icon img{float: none;}
// .voice.right.n_bottom .icon .name{
//   margin-left: 20px;
//   text-align: center;
//   line-height: 1;
//   max-width: 70px;
//   margin-top: -10px;
// }
