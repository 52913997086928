@import "../module/settings";

.diver-review-box {
    $this: &;
    box-shadow: $box-shadow;
    border-radius: 10px;
    max-width: 500px;
    margin: 60px auto 1.5em !important;
    padding: 1.5em;
    text-align: center;
    // &__author {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    & > p:not([class]){
        margin: 0;
    }
    &__image {
        margin: calc(-1.5em - 50px) auto 1em;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
    }
    &__name {
        font-size: 0.8em;
        opacity: .6;
        line-height: 1;
    }
    & > .diver-review {
        justify-content: center;
    }
    &__title {
        font-weight: bold;
        margin: 0;
    }
    &__content {
        text-align: left;
        font-size: 0.9em;
        margin: 1em 0 0;
        padding: 1em;
        display: flex;
        background-color: transparent;
        box-shadow: none;
        justify-content: center;
        align-items: center;
        &:before {
            top: -10px;
            left: -10px;
        }
        &:after {
            bottom: -10px;
            right: -10px;
        }
    }
}


/****************************************

          diver voice

*****************************************/



.diver_voice_wrap{
    margin: 20px 0 30px;
    display: flex;
    gap: 10px;
    figure{
        width: 25%;
        margin-right: 10px;
    }
    .diver_voice_icon{
        width: 100%;
        margin: 0;
        border-radius: 5px;
        object-fit: cover;
        box-shadow: none;
        border:none;
    }
    .diver_voice_title {
        font-size: 1.1em;
        border-bottom: 1px solid #ddd;
        padding-bottom: 7px;
    }
    .review_star{
        display: block;
    }
    .diver_voice_content {
        line-height: 1.5;
        padding: 5px 0;
        font-size: .9em;
    }
    .diver_voice_name {
        font-size: .9em;
        color: #f17e00;
        text-align: right;
        line-height: 1;
    }

    @include mq-down(md) {
        figure{
            margin: 0;
        }
        .diver_voice_icon {
            margin: 0 auto;
        }
    }
    
}


.content .diver_voice_title p {
    padding: 0;
    margin: 0;
}


.diver_voice {
  position: relative;
  flex: 1;
  padding: 10px 15px;
  color: #19283C;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  z-index: 0;
  box-shadow: 0 4px 4px #ddd;
  box-shadow: 0 4px 4px rgba(130, 130, 130, 0.5);

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;

  }

  &::before {
    content: "";
    top: 1em; left: -8px;
    margin-top: -9px;
    border-width: 9px 9px 9px 0;
    border-color: transparent #fff transparent transparent;
    z-index: 0;
  }
  &::after {
    top: 1em; left: -10px;
    margin-top: -10px;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ddd transparent transparent;
    z-index: -1;
  }
}
