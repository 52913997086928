/*
Theme Name: Twenty Fifteen
Description: Used to style blocks.
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 General Block Styles
2.0 Blocks - Common Blocks
3.0 Blocks - Formatting
4.0 Blocks - Layout Elements
5.0 Blocks - Widgets
6.0 Blocks - Colors
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 General Block Styles
--------------------------------------------------------------*/


:where(.content){
    div:not([id]):not([class]):not([style]),
    & > [class^="wp-container-"],
    & > [class^="wp-block"]:not(.wp-block-dvaux-section){
        z-index: 2;
        position: relative;
        min-height: 1em;
        margin-bottom: 1.5em;
    }
}

/* Captions */

[class^="wp-block-"] figcaption {
	color: #707070;
	font-family: "Noto Sans", sans-serif;
	font-size: 12px;
	line-height: 1.5;
	margin-bottom: 0;
	padding: 0.5em 0;
}

@media screen and (min-width: 46.25em) {
	[class^="wp-block-"] figcaption {
		font-size: 14px;
	}
}

@media screen and (min-width: 55em) {
	[class^="wp-block-"] figcaption {
		font-size: 16px;
	}
}

@media screen and (min-width: 59.6875em) {
	[class^="wp-block-"] figcaption {
		font-size: 12px;
	}
}

@media screen and (min-width: 68.75em) {
	[class^="wp-block-"] figcaption {
		font-size: 14px;
	}
}

@media screen and (min-width: 77.5em) {
	[class^="wp-block-"] figcaption {
		font-size: 16px;
	}
}

/* Alignments */

[class^="wp-block-"].alignleft,
[class^="wp-block-"] .alignleft {
	margin-right: 1em;
}

[class^="wp-block-"].alignright,
[class^="wp-block-"] .alignright {
	margin-left: 1em;
}


/*--------------------------------------------------------------
2.0 Blocks - Common Blocks
--------------------------------------------------------------*/

/* Paragraph */

p.has-drop-cap:not(:focus)::first-letter {
	font-size: 5em;
}

/* Gallery */

.wp-block-gallery {
	margin-bottom: 1.6em;
}

/* Audio */

.wp-block-audio audio {
	display: block;
	width: 100%;
}

/* Cover */

.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
.wp-block-cover-image h2,
.wp-block-cover h2 {
	font-size: 29px;
	line-height: 1.2069;
}

/* Quote */

.wp-block-quote,
.wp-block-quote:not(.is-large):not(.is-style-large),
[class^="wp-block-"] blockquote {
	border-color: #707070;
}

.wp-block-quote cite,
.wp-block-quote__citation {
	color: #333;
	font-family: "Noto Sans", sans-serif;
	font-size: .9em;
	font-style: normal;
	line-height: 1.6;
	text-transform: none;
}



@media screen and (min-width: 46.25em) {
	.edit-post-visual-editor .wp-block-quote p {
		font-size: 20px;
		line-height: 1.75;
	}

}

@media screen and (min-width: 55em) {
	.edit-post-visual-editor .wp-block-quote p {
		font-size: 22px;
		line-height: 1.8182;
	}

}

@media screen and (min-width: 59.6875em) {
	.edit-post-visual-editor .wp-block-quote p {
		font-size: 18px;
		line-height: 1.6667;
	}

}

@media screen and (min-width: 68.75em) {
	.edit-post-visual-editor .wp-block-quote p {
		font-size: 20px;
		line-height: 1.75;
	}

}

@media screen and (min-width: 77.5em) {
	.edit-post-visual-editor .wp-block-quote p {
		font-size: 22px;
		line-height: 1.8182;
	}

}

/* Cover Image */

.wp-block-cover-image.aligncenter,
.wp-block-cover.aligncenter,
.wp-block-cover-image.alignleft,
.wp-block-cover.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignright {
	display: flex;
}

/* File */

.wp-block-file a.wp-block-file__button {
	background-color: #333;
	border: 0;
	border-radius: 0;
	color: #fff;
	cursor: pointer;
	font-family: "Noto Sans", sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 1.5;
	padding: 0.7917em 1.5em;
	text-transform: uppercase;
	vertical-align: baseline;
	border-radius: 20px;
}

.wp-block-file a.wp-block-file__button:hover,
.wp-block-file a.wp-block-file__button:focus {
	background-color: #707070;
	background-color: rgba(51, 51, 51, 0.7);
	outline: 0;
}

.rtl .wp-block-file * + .wp-block-file__button {
	margin-left: 0.75em;
	margin-right: 0;
}


/*--------------------------------------------------------------
3.0 Blocks - Formatting
--------------------------------------------------------------*/

/* Pullquote */

.wp-block-pullquote {
	border: 0;
}

.wp-block-pullquote blockquote {
	border: 0;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
}

.wp-block-pullquote cite {
	color: #333;
	font-family: "Noto Sans", sans-serif;
	font-size: 15px;
	font-style: normal;
	line-height: 1.6;
	text-transform: none;
}

@media screen and (min-width: 46.25em) {
	.wp-block-pullquote cite {
		font-size: 17px;
		line-height: 1.6471;
	}
}

@media screen and (min-width: 55em) {
	.wp-block-pullquote cite {
		font-size: 19px;
		line-height: 1.6842;
	}
}

@media screen and (min-width: 59.6875em) {
	.wp-block-pullquote cite {
		font-size: 15px;
		line-height: 1.6;
	}
}

@media screen and (min-width: 68.75em) {
	.wp-block-pullquote cite {
		font-size: 17px;
		line-height: 1.6471;
	}
}

@media screen and (min-width: 77.5em) {
	.wp-block-pullquote cite {
		font-size: 19px;
		line-height: 1.6842;
	}
}

/* Table */

.wp-block-table th,
.wp-block-table td {
	border-color: #eaeaea;
	border-width: 0 1px 1px 0;
}

/*--------------------------------------------------------------
4.0 Blocks - Layout Elements
--------------------------------------------------------------*/

/* Button */

.wp-block-button .wp-block-button__link {
	border: 0;
	cursor: pointer;
	font-family: "Noto Sans", sans-serif;
	font-weight: 700;
	line-height: 1.5;
	padding: 0.7917em 1.5em;
	text-transform: uppercase;
	vertical-align: baseline;
	text-decoration: none;
}

.wp-block-button__link {
	background-color: #333;
	color: #fff;
}

.wp-block-button__link:hover,
.wp-block-button__link:focus {
	background-color: #707070;
	background-color: rgba(51, 51, 51, 0.7);
	color: #fff;
}

/* Seperators */

.wp-block-separator {
	max-width: 100px;
    border: none;
    border-bottom: 2px solid #8f98a1;
    margin: 1.65em auto;
}
.wp-block-separator.is-style-wide {
	max-width: 100%;
}

/* Media & Text */

.wp-block-media-text {
	margin-bottom: 1.6em;
}

.wp-block-media-text *:last-child {
	margin-bottom: 0;
}

/*--------------------------------------------------------------
5.0 Blocks - Widgets
--------------------------------------------------------------*/

/* Archives, Categories & Latest Posts */

.wp-block-archives.aligncenter,
.wp-block-categories.aligncenter,
.wp-block-latest-posts.aligncenter {
	list-style-position: inside;
	text-align: center;
}

/* Latest Comments */

.wp-block-latest-comments {
	margin: 0;
}

.wp-block-latest-comments__comment,
.wp-block-latest-comments__comment-excerpt,
.wp-block-latest-comments__comment-excerpt p {
	font-size: inherit;
}

.wp-block-latest-comments__comment-meta a {
	border-bottom-color: transparent;
	font-weight: 700;
}

.wp-block-latest-comments__comment-excerpt p:last-child {
	margin-bottom: 0;
}

.wp-block-latest-comments__comment-date {
	color: #707070;
	font-family: "Noto Sans", sans-serif;
	font-size: 12px;
	line-height: 1.5;
	margin-bottom: 1.6em;
}

@media screen and (min-width: 46.25em) {
	.wp-block-latest-comments__comment-date {
		font-size: 14px;
	}
}

@media screen and (min-width: 55em) {
	.wp-block-latest-comments__comment-date {
		font-size: 16px;
	}
}

@media screen and (min-width: 59.6875em) {
	.wp-block-latest-comments__comment-date {
		font-size: 12px;
	}
}

@media screen and (min-width: 68.75em) {
	.wp-block-latest-comments__comment-date {
		font-size: 14px;
	}
}

@media screen and (min-width: 77.5em) {
	.wp-block-latest-comments__comment-date {
		font-size: 16px;
	}
}

.wp-block-latest-comments .wp-block-latest-comments__comment {
	border-top: 1px solid #eaeaea;
	border-top: 1px solid rgba(51, 51, 51, 0.1);
	margin-bottom: 0;
	padding: 1.6em 0;
}

/*--------------------------------------------------------------
6.0 Blocks - Colors
--------------------------------------------------------------*/

.content .has-light-blue-color {
	color: #70b8f1;
}

.content .has-light-blue-background-color {
	background-color: #70b8f1;
}

.content .has-light-red-color {
	color: #ff8178;
}

.content .has-light-red-background-color {
	background-color: #ff8178;
}

.content .has-light-green-color {
	color: #2ac113;
}

.content .has-light-green-background-color {
	background-color: #2ac113;
}

.content .has-light-yellow-color {
	color: #ffe822;
}

.content .has-light-yellow-background-color {
	background-color: #ffe822;
}

.content .has-light-orange-color {
	color: #ffa30d;
}

.content .has-light-orange-background-color {
	background-color: #ffa30d;
}

.content .has-white-color {
	color: #fff;
}

.content .has-white-background-color {
	background-color: #fff;
}

.content .has-blue-color {
	color: #00f;
}

.content .has-blue-background-color {
	background-color: #00f;
}

.content .has-red-color {
	color: #f00;
}

.content .has-red-background-color {
	background-color: #f00;
}

.content .has-purple-color {
	color: #674970;
}

.content .has-purple-background-color {
	background-color: #674970;
}

.content .has-black-color {
	color: #000;
}

.content .has-black-background-color {
	background-color: #000;
}

.content .has-gray-color {
	color: #ccc;
}

.content .has-gray-background-color {
	background-color: #ccc;
}
